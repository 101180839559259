import * as React from "react"
import { Link } from "gatsby"
import Button from "../ProductButton/Button"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const InfoFeature = ({ infofeature }) => {
  const { title, slug, image, introduction } = infofeature
  const image1 = getImage(image)

  return (
    <aside className="infofeatures__item">
      <Link to={`/infos/${slug}`}>
      <GatsbyImage
          className="infofeatures__item--img"
          image={image1}
          alt="Info Image"
        />
      
        
        <div className="infofeatures__item--content">
          {title && <h2>{title}</h2>}
          {introduction && <p>{introduction}</p>}
          <Button text="Прочети" as="span" />
        </div>
      </Link>
    </aside>
  )
}

export default InfoFeature
