import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import InfoFeatures from "../components/Info/Infos"
import Seo from "../components/SEO"
import Button from "../components/Button/Button"
import { BlogSingleStyles } from "../components/Info/InfosStyles"

const infos = () => {
  return (
    <>
      <Seo title="Infos" />
      <Layout>
        <BlogSingleStyles>
            <h1 className="blogsingle__title">Полезна информация</h1>          
            <article className="blogsingle__content">
            </article>
        </BlogSingleStyles>
        <InfoFeatures />
        <BlogSingleStyles>
            <div className="blogsingle__back">
              <Button text="Към начална страница" as={Link} to="/" />
            </div>           
        </BlogSingleStyles>         
      </Layout>
    </>
  )
}

export default infos
