import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { InfosStyles } from "./InfosStyles"
import Info from "./Info"

const getInfos = graphql`
query InfoQuery {
  infos: allContentfulInfo {
    edges {
      node {
        title
        slug
        introduction
        image {
          gatsbyImageData(width: 600, formats: [AUTO, WEBP])
        }
      }
    }
  }
}
`

const InfoFeatures = () => {
  const response = useStaticQuery(getInfos)
  const infos = response.infos.edges

  return (
    <InfosStyles>
      <div className="infofeatures__container">
        <div className="infofeatures__container--scroll">
          {infos.map(({ node }) => {
            return <Info infofeature={node} />
          })}
        </div>
      </div>
    </InfosStyles >
  )
}

export default InfoFeatures
